import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj9 = () => {
  return (
    <Layout>
      <SEO
        title="Your own Database System"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>
      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-9">
          <div className="py-1-heading">
            <h1>Your own Database System</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Your%20Own%20Database%20System"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              This is a database system for routine work.
              <br />
              We can add data for date, excercise, diet, earnings, study,
              python.
              <br />
              also search, delete, view all, close functions can be used on the
              database.
              <br />
              <br />
              The backend is configured through Sqlite3.
              <br />
              <br />
              Let me tell you one very interesting thing about this project.
              <br />
              A final year student can also perform this project, even as a
              major project.
              <br />
              That's the significance of this project.
              <br />
              <br />
              Amazing, isn't it?
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Tkinter</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Backend</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Sqlite3</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj9
